.faq-card {

	border: none;
	background-color: #fff;
	.card-header {
		background-color: #fff;
		padding: 0.45rem 0.25rem;
		text-decoration: none;
	}
	.card-body {
		padding: 1rem 1.4rem;
	}
	.faq-question {
		text-decoration: none;
		white-space: normal;
		svg {
			margin-right:5px;

		}
		svg.plus {
		    display: inline-block;
		}
		svg.minus {
		    display: none;
		}
	}
	.faq-question[aria-expanded="true"] {
		svg.plus {
		    display: none;
		}
		svg.minus {
		    display: inline-block;
		}
	}
	.faq-answer {



	}
}