// Select 2

@import '~select2';

.select2-selection--single {
  border-radius: 0 !important;
  height: auto !important;
  border: 1px solid #ced4da !important;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;

}
.select2-selection--single:before {
  content: "";
  position: absolute;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23222529' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 12px;
  width: 16px;
  height: 12px;
  right: 0.75rem;
  top:15px;
}
.select2-container--open {
  .select2-selection--single:before {
    transform: rotate(180deg);
  }
}
.select2-selection__arrow {
  display: none !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 100%;
  right: 0.75rem;
}