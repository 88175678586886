/* Shop Top Bar */
.shop-top-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin-bottom: 30px;

    gap: 10px;
    &-item {
        display: flex;
        align-items: center;
        align-self: center;

        gap: 10px;
        & label {
            font-size: 14px;

            margin: 0;
        }
        & select {
            font-size: 14px;

            padding-right: 15px;

            border: none;
            background-color: transparent;
        }
        & p {
            font-size: 14px;
        }
        & .list-grid-toggle {
            display: flex;

            gap: 20px;
            & button {
                line-height: 1;

                display: flex;

                padding: 5px;

                border: none;
                background-color: transparent;
                & i {
                    font-size: 18px;
                }
                &.active {
                    color: $primary;
                }
            }
        }
    }
}

/* Shop Bottom Bar */
.shop-bottom-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    margin-top: 50px;
}

.shop-cards {

    text-align: center;

    img {
        height: 30px;
        margin:2px;
    }
}
.shop-cards-test {

    img {
        height: 50px;
    }
}

.category-box-card {
    .card-title {
        position: absolute;
        bottom: 10px;
        text-align: center;
        background-color: rgba(60,60,60,.7);
        font-size: 22px;
        font-weight: 500;
        color: #fff;
        line-height: 35px;
        display: inline-block;
        padding: 0 20px;
        width: 100%;
    }
}

#product-list-box {
    @media #{$large-mobile} {
        display: block !important;
        opacity: 1 !important;
    }
}

#product-list-row {
    @media #{$large-mobile} {
        display: none !important;
    }
}

#product-list-table {
    @media #{$large-mobile} {
        display: none !important;
    }
}
