
.sidebar-menu {
  ul {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  & > ul {
    & > .nav-item{
      border-bottom:1px solid $main;
    }
  }

  .nav-item{
    position: relative;
  }


  .nav-link{
    margin-right:30px;
    font-size:1rem;
    padding: 10px 0 7px 0;
    color:#373737;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
  }

  li.active > .nav-link,
  .nav-link:hover{
    color:$primary;
  }

  .nav-sub-toggle  {
    float:right;
    cursor: pointer;
    line-height: inherit;
    position: absolute;
    right:10px;
    top: 10px;
  }

  .submenu  {
    margin-left:20px;
    //border-left:1px solid #e9e7e7;
    margin-bottom: 5px;
  }

  .nav-sub-toggle{
    width: 60px;
    height: 35px;

  }
  .nav-sub-toggle:not(.collapsed) {
    color: #1F2221;
    background-color: transparent;
    box-shadow: none;
  }
  .nav-sub-toggle i::before {
    display: block;
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
  }
  .nav-sub-toggle.collapsed i::before {

  }
  .nav-sub-toggle:not(.collapsed) i::before {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
  }

}
