/*----------------------------------------*/
/*  01. Template default CSS
/*----------------------------------------*/
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

/* Common Style */
*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
}

body {
    font-family: $font-family-base;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    font-style: normal;
    line-height: $line-height-base;

    position: relative;

    visibility: visible;
    overflow-x: hidden;

    color: $body-color;
    background-color: $white;
    background-repeat: no-repeat;
    background-position: top center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;

    margin-top: 0;
    margin-bottom: 8px;

    color: $headings-color;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    line-height: inherit;

    margin: 0;
}
p {
    margin-top: 0;
    &:last-child {
        margin-bottom: 0;
    }
}

a,
button {
    line-height: inherit;

    display: inline-block;

    cursor: pointer;
    text-decoration: none;

    color: inherit;
}

a,
button,
input {
    transition: $transition-base;
}

*:focus {
    outline: none;
}

a:focus {
    text-decoration: none;

    color: inherit;
    outline: none;
}

a:hover {
    text-decoration: none;

    color: $primary;
}

button,
input[type="submit"] {
    cursor: pointer;
}
/*
img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
}*/

input,
textarea {
    @include placeholder {
        opacity: 1;
    }
}

ul {
    margin-top: 0;
    &:last-child {
        margin-bottom: 0;
    }
}

hr {
    border-top-width: 2px;
}
