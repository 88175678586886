.breadcrumb-item + .breadcrumb-item::before {
  content: none !important;
}
.breadcrumb-item + .breadcrumb-item{
  padding-left: 0;
}

.list-group {


}
.btn-wishlist {
  padding: 0;
  font-size: 20px;
  color: $secondary;
}

.add-to-cart-container {
    @media #{$large-mobile} {
        width: 100%;
    }
}

.btn-success {
  color: #fff;
}

.form-group {
    label {
        margin-bottom: 5px;
    }
    margin-bottom: 15px;
}

.form-row {
	margin-bottom: 15px;
}

ul.flag-list li a {
    opacity: 0.8;
}

ul.flag-list li a.active {
    opacity: 1;
}

.owl-nav.disabled {
  display: block !important;
}