/* Sekcija novosti */
.articles-section {
  text-align: center;
  padding: 40px 0 10px;
  background-color: #f2f2f2;
  .section-heading {
    color:$black;
    margin-bottom: 20px;
  }
}
/* Article card */
.article-card  {
    margin-bottom:1rem;
    border: 1px solid #f2f2f2;
    border-radius: calc(0.25rem - 1px);

    .card-body {
        padding:0.8rem 0.6rem;
    }
    .card-footer {
        border:none;
        padding: 0;
        background-color:transparent;
    }
    .card-title {
        font-size: 1.25rem;
        font-family: $headings-font-family;
        font-weight: 600;
        margin-bottom: 1rem;
        margin-top: 0.5rem;
        color:$gray-800;
    }

    .card-title a{
        color:inherit;
    }

    .card-subtitle {
        margin-bottom: 0.5rem;
    }


    .badge {
        font-size:0.875rem;
    }
}
/* Article card */
.article-card:hover  {

    .card-title {
        color:$red;
    }

}
