/* Page Banner / Breadcrumb */
.page-banner-section {
    position: relative;

    padding: 16px 0;
    background-color: #efefef;
    /*background-image: url('/images/page-banner-bg.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;*/
}
