/* Breadcrumb CSS */
.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;

    margin: 0;
    padding: 0;

    list-style: none;

    gap: 1px;
    & li {
        display: flex;

        //text-transform: uppercase;

        color: $gray-800;
        a {
            color: $gray-800;
        }
        &.active {
            color: $primary;
        }

        gap: 1px;
        &:not(:last-child) {
            &::after {
                display: block;
                align-self: center;

                width: 18px;
                height: 1px;

                content: "";
                transform: rotate(115deg);

                background-color: $gray-700;
            }
        }
    }
}
