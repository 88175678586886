/* Sections */
section {
	padding: 30px 0;
}

.section-heading {
    margin-top: 0px;
    font-size: 2rem;
    color: $black;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.5px;
	a {
	    color: $primary;
	}
	a:hover {
	    color: $secondary;
	}
}


.heading-border {
	position: relative;
	border-bottom: 1px solid $border-color;
	background-color: $main;
	margin-bottom: 30px;
}

.heading-border::before {
    position: absolute;
    bottom: -1px;
    width: 60px;
    height: 4px;
    background-color: inherit;
    content: '';
}

.heading-border::after {
    position: absolute;
    bottom: -1px;
    width: 25px;
    height: 4px;
    background-color: inherit;
    content: '';
    left: 63px;
}

.section-subheading {
	font-size: 1.125rem;
	font-weight: 400;
	margin-bottom: 45px;
    margin-top: 5px;
	text-transform: none;
}


