@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/card";

.card {
    
    //border-radius: 0;
    overflow: hidden;

    .card-link-overlay {
        z-index: 5;
        left:0;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .card-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        z-index: 3;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transition: opacity .4s cubic-bezier(0.165,0.84,0.44,1);
        transition: opacity .4s cubic-bezier(0.165,0.84,0.44,1);
        opacity: 0;
    }

    .card-img-overlay {
        padding: 30px;  
        opacity: 1;
        z-index: 4;
        display: flex;
        align-items: end;
        -webkit-transition: all .25s ease-in-out;
        -moz-transition: all .25s ease-in-out;
        -ms-transition: all .25s ease-in-out;
        -o-transition: all .25s ease-in-out;
        transition: all .25s ease-in-out;
    }

    .card-img-container {
        overflow: hidden;
    }

    .card-img {
        webkit-transition: -webkit-transform .15s ease-out;
        -moz-transition: -moz-transform .15s ease-out;
        -ms-transition: -ms-transform .15s ease-out;
        -o-transition: -o-transform .15s ease-out;
        transition: transform .15s ease-out;
    }

    .card-title {

    }
    .card-subtitle {
        
    }
    .img-container {
        position: relative;
    }
    .img-label {
        position: absolute;
        left:0;
        background-color: $primary;
        color:#fff;
        padding: 5px;
    }
    .img-label-left-down {
        position: absolute;
        left:0;
        bottom: 0;
        right:auto;
        top:auto;
    }
    .img-label-left-top {
        position: absolute;
        left:0;
        bottom: auto;
        right:auto;
        top:0;
    }
}

.card-zoom:hover  {
    overflow: hidden;

    .card-img-overlay {
        opacity: 1; 
    }
    .card-overlay {
        opacity: 1; 
    }
    .card-img {
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1);
    }
}