/* Accordion CSS */
.accordion {
    display: flex;
    flex-direction: column;

    gap: 5px;
    &-item {
        overflow: hidden;

        border: 1px solid $border-color !important;
        border-radius: 4px !important;
    }
    &-button {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        box-shadow: none !important;
        &::after {
            transition: $transition-base;
        }
        &:not(.collapsed) {
            color: $white;
            background-color: $primary;
            &::after {
                filter: brightness(0) invert(1);
            }
        }
    }
    &-body {
        border-top: 1px solid $border-color;
    }
}
