/* Full width section */

.split-section {
	z-index: 10;
	position: relative;
	background-color: $gray-900;
	color: #aaaaaa;
	padding: 80px 0;

	.col-image {
		.img-side {
		    right: 0;
		    padding: 0;
		    position: absolute !important;
		    top: 0px;
		    height: 100%;
		    overflow: hidden;
		    z-index: 100;
		}
		.img-side.img-left {
		    left: 0;
		}
		.img-holder {
		    position: absolute;
		    top: 0;
		    width: 100%;
		    height: 100%;
		}
		.img-cover {
			background-size: cover !important;
		    background-position: center center !important;
		}

	}


	.col-content {
		.section-heading {
			color:$white;
		}
		.section-subheading {
			color:$primary;
			margin-bottom: 5px;
		}
		.bold-line {
		    margin: 10px 0;
		}
		.section-text {

		}
	}
}